import React from "react"
import Banner from "../components/Banner"
import Layout from "../components/Layout";
import {Link} from "../../.cache/gatsby-browser-entry";

import contactBanner from "../images/contact-banner.png";
import SEO from "../components/SEO";




export default ({ location }) => {
    const params = new URLSearchParams(location.search);
    const name = params.get("name");
    const email = params.get("email");
    const country = params.get("country");
    const phone = params.get("phone");
    const company = params.get("company");
    const region = params.get("region");
    const message = params.get("message");

    const ThankYouTitle = () => <h1 className="text-align-center page-title">Thank you</h1>
    const ThankYouBody = () =>
        <p className="text-align-center">You are very important to us. We will contact you soon.
            <br /> Please check out the website for products and services.</p>

    const Submission = () => <div className="submission text-align-left">
        <h3>Submission:</h3>
        <p>
            <div className="row">
                <div className='col col-md-6'>
                    You name:
                </div>
                <div className='col col-md-6'>
                    {name}
                </div>
            </div>
            <div className="row">
                <div className='col col-md-6'>
                    Email:
                </div>
                <div className='col col-md-6'>
                    {email}
                </div>
            </div>
            <div className="row">
                <div className='col col-md-6'>
                    Country:
                </div>
                <div className='col col-md-6'>
                    {country}
                </div>
            </div>
            {
                region ? (
                  <div className="row">
                      <div className='col col-md-6'>
                          County:
                      </div>
                      <div className='col col-md-6'>
                          {region}
                      </div>
                  </div>
                ) : null
            }
            <div className="row">
                <div className='col col-md-6'>
                    Phone:
                </div>
                <div className='col col-md-6'>
                    {phone}
                </div>
            </div>
            <div className="row">
                <div className='col col-md-6'>
                    Company:
                </div>
                <div className='col col-md-6'>
                    {company}
                </div>
            </div>
            <div className="row">
                <div className='col col-md-6'>
                    Message:
                </div>
                <div className='col col-md-6'>
                    {message}
                </div>
            </div>
            <hr/>
            <Link className="link-to-home" to="/">Return to home</Link>
        </p>
    </div>


    return (
        <Layout>
            <SEO title="thank you page" />
            <div className="wrapp-contact-banner">
            <section className="container contact-banner">
                <div className="row">
                    <div className='col-md-12 thank-you content-wrapper'>
                        <picture>
                            <img className="contact-img" src={contactBanner}/>
                        </picture>
                    </div>
                </div>
            </section>
            </div>
            <section className="container thank-you-title">
                <div className="row">
                    <div className='col-md-12 thank-you content-wrapper'>
                        <ThankYouTitle />
                    </div>
                </div>
            </section>
            <section className="container thank-you-body">
                <div className="row">
                    <div className='col-md-12 thank-you content-wrapper'>
                        <ThankYouBody />
                    </div>
                </div>
            </section>
            <section className="container thank-you-submissions">
                <div className="row">
                    <div className='col-md-4 thank-you content-wrapper'>
                    </div>
                    <div className='col-md-4 thank-you content-wrapper'>
                        <Submission />
                    </div>
                    <div className='col-md-4 thank-you content-wrapper'>
                    </div>
                </div>
            </section>
        </Layout>
    );

};
